import styled from "styled-components"
import { BaseContainer } from '../BaseContainer/styles.js'
import Container from 'react-bootstrap/Container'
import { default as OrgRow } from "react-bootstrap/Row"
import { default as OrgCol } from "react-bootstrap/Col";
import { Link } from "gatsby";
import { colors } from '../../styles/GlobalStyles.js'

export const ProductLink = styled(Link)`
    :hover {
        text-decoration: none !important;
    }
`

export const ListContainer = styled(Container)`
    
    padding-left: 0px;
    padding-right: 0px;
`
export const ListCol = styled(Container)`
    padding-left: 0px;
    padding-right: 0px;
`




export const Row = styled(OrgRow)`
    margin-right: 0px;
    margin-left: 0px;
`

export const Col = styled(OrgCol)`
    padding-bottom: 0px;
`

export const WithoutDiscount = styled.div`
    font-size:          .6em;
    text-decoration:    line-through;
    font-weioght:       normal;
    color:              #77c0ff;

`