import styled from "styled-components"
import Container from 'react-bootstrap/Container'

// import { colors } from '../../styles/GlobalStyles.js'

export const BaseContainer = styled(Container)`
    padding: 0px;

    .col {
        // border: 1px solid #0f0;
        padding: 0px;
    }

    .row .Description {
        Padding: 0px 2px;
        h3 {
            font-weight: bold;
            margin: 0px;
        }
        p {
            font-size: 1.0rem;
            margin: 0px 0px 15px 0px;
        }
        p:last-child {
            margin: 0px 0px 5px 0px;
        }
    }
`