import * as React from "react"
// Components
import ContentfulImage from "../ContentfulImage"

// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

// styles
import { ListContainer, ListCol, Row } from "./styles.js"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import { getHumanReadableDuration } from "../../functions/getHumanReadableDuration"
import { ProductBox } from "../../_shared/ProductBox"

const getSlug = require('../../functions/getSlug')

// Return a string similar to: '12 hours, 0 minutes' or "no length of activity provided"
const getLengthInTimeAsString = (node) => {
  // use the next sessions 'lengtInTime'
  if (!node.durationInMinutes) {
    return "no length of activity provided"
  }

  return getHumanReadableDuration(node.durationInMinutes)
}

// Display a list of all products - likely for use on the homepage.
export const ProductList = ({ products }) => {
  {console.log("[#fh7] in products list" )}
  return (
    <ListContainer>
      {products.map((node) => {
        console.log("product #123343: ", node)
        
        return (
          <Row key={node.id}>
             <ListCol className="justify-content-center">
                 <ProductBox product={node} />
             </ListCol>
          </Row>
        )
      })}
    </ListContainer>
  )
}

export default ProductList